const huskisms = {
	activities: [
		'How about you treat me to my lunch?',
		'I’m off to get some new threads.',
		'I\'ve got a kilt fitting I need to get to.',
		'Let’s get few pots organised!',
		'Let\'s get tanked up!',
		'Let’s get potted up!',
		'Let’s get sparkled!',
		'Let’s get to the gymnasium.',
	],
	answers: [
		//Yes
		'Absolutely not!',
		'Does a bear shit in the woods?',
		'Good shout.',
		'Let’s go for the jugular.',
		'Oh yes!',
		'Roger.',
		'That’s a sweet dream.',
		'Yes. Trust me.',
		'Yes. Tout suite.',

		//No
		'Deary me.',
		'Easy now!',
		'It\'s borked!',
		'Looks like a dead loss.',
		'Naw!',
		'No way, man!',
		'That’s pure conjecture.',
		'The game\'s a bogey.',
		'Wind your neck in!',

		// Other
		'I\'m ambivalent.',
		'I\'m coming up with... blankety blank.',
		'I\'m panzershreked. Try again later. ',
		'Testing a thesis on this now… Ask again later.',
		'Why’s that pertinent?',
	],
	coversational: [
		'I\'m panzered.',
		'You and me, the old double act.',
		'You need to see this!',
		'You’ve got to see this!',
		'You’re good stock.',
		'We’re not playing for points, here.',
		'You shouldn’t be standing there casting aspersions!',
	],
	exclamations: [
		'Are you sure about that?',
		'Bingo Bango!',
		'Boffinry!',
		'Carpet baggers!!',
		'Come on man!',
		'Concentrate!',
		'He’s about as useful as a chocolate teapot!',
		'How can that be possible?',
		'Huh?',
		'I certainly will not.',
		'I’ll do your dentist a favour.',
		'I’m serious!',
		'Nice touch.',
		'Oh well!',
		'Potless.',
		'She thinks my arse is lined with diamonds!',
		'That is quite possibly the funniest thing I’ve ever seen!',
		'This is excruciating!',
		'Well that didnae work.',
		'Wee jacko.',
		'Woft.',
		'😡',
	],
	food: [
		'A wee boost?',
		'Let’s get pretzeled up.',
		'Oh well!',
		'Oh yes!',
	],
	foodDecision: [
		'A wee Ichiban.',
		'Chicken Royale',
		'Marks and Sparks.',
		'The colonel.',
		'The golden arches.',
	],
	foodPost: [
		'I’d have that again.',
		'I’m away for a pan drop.',
		'I shouldnae have ate all that.',
		'Snaffled!',
		'Succulent.',
		'Succulenté.',
		'That’s a sweet dream.',
	],
	goodbyes: [
		'Get yoursel’ up the road.',
		'I’ll see you tomorrow bright as a button',
		'Let’s louse.',
	],
	greetings: [
		'At last!',
		'Am ready.',
		'I\'m on tenterhooks here.',
		'I’ve been waiting hours for you to turn up!',
		'I mean business.',
		'Nice of you to join us!',
		'Listen...',
		'Picture the scene...',
		'Picture the scenario...',
		'There’s been a development.',
		'U wot m8',
		'What\'s the hold up here?',
		'What time do you call this?',
	],
	names: [
		'Blairio',
		'Gazmatraz',
		'Keynote',
		'Me ol\' duck',
		'Sazzler',
		'Stupot',
	],
	questions: [
		'Can I help you?',
		'Do you actually know what you’re doing?',
		'Huh?',
		'I’m trying to get on for some work here!',
		'I\'ve got something to say and I don’t mind telling you.',
		'Let’s dispense with the verbal sparring.',
		'Let’s just see, shall we?',
		'Let\'s talk if you think you’re so smart.',
		'Let’s talk tactics.',
		'We need to talk and I mean A S A P.',
		'What’s the issue?',
		'What’s the scandal?',
		'What’s your major malfunction?',
		'What’s your problem, bro?',
	],
	wisdom: [
		'Always keep your promises if you want to keep your friends.',
		'Don’t put all your chips in the one oven.',
		'Don’t put off till tomorrow what you can do today.',
		'He who hingeth about, gets hee-haw.',
		'Keep it pointed at the stars.',
		'We don’t draw up the foundations, we just lay the brix.',
		'You canny hide money.',
	],
};

export { huskisms };
